import React, { useState } from 'react'
import Header from '../../components/Header'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, IconButton, Modal, TextField, Icon } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { add_category, delete_category, get_categories, update_category, update_category_img } from '../../config/constants';
import { useEffect } from 'react';
import ImageRender from '../../components/imageRenderinTable/ImageRender';
import { getSerialNumber } from '../../config/methods';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { Check, Close } from '@mui/icons-material';
import { alertOptions } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';

const Category = () => {
    const [categoryData, setCategoryData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modelMode, setModalMode] = useState('edit')
    const [imgBtnStatus, setImgBtnStatus] = useState(false)
    const [uploadBtn, setUploadBtn] = useState(false)

    const [state, setState] = useState({
        _id: '',
        categoryName: '',
        img: { url: require('../../assets/jsv_cards_logo.png'), bytes: '' },
        oldImg: '',
        originalImg: ''
    })
    const { _id, categoryName, img, oldImg, originalImg } = state

    useEffect(() => {
        fetch_all_categories()
    }, [])

    const updateState = (data) => {
        setState((prevData) => {
            const newData = { ...prevData, ...data };
            return newData;
        })
    }

    const fetch_all_categories = async () => {
        setIsLoading(true);
        const response = await getData(get_categories);
        setIsLoading(false);
        if (response.status) {
            setCategoryData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const handleModelOpen = async (rowData, mode) => {
        setModalOpen(true)
        setModalMode(mode)
        setUploadBtn(false)
        if (mode == 'edit') {
            try {
                updateState({
                    _id: rowData._id,
                    categoryName: rowData.categoryName,
                    img: { url: rowData.imgUrl, bytes: '' },
                    originalImg: { url: rowData.imgUrl, bytes: '' },
                    oldImg: rowData.imgUrl,
                });
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        }
        else {
            handleReset()
        }
    }

    const closeModel = () => {
        setModalOpen(false)
        setModalMode('edit')
        setImgBtnStatus(false)
    }

    const handleReset = () => {
        updateState(
            {
                categoryName: '',
                img: { "url": require('../../assets/jsv_cards_logo.png'), bytes: '' }
            })
    }

    const validation = () => {
        var isValid = true;
        if (!categoryName) {
            toast.error('Category Name can not be blank')
            isValid = false
        }
        if (!img.bytes) {
            toast.error('Please Select an Category image')
            isValid = false
        }
        return isValid;
    }

    const handleSubmit = async () => {
        if (modelMode == 'add') {
            if (validation()) {
                setIsLoading(true)
                var formData = new FormData()
                formData.append('categoryName', categoryName)
                formData.append('img', img.bytes)
                const response = await postData(add_category, formData, true)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_categories()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
        else {
            if (categoryName) {
                setIsLoading(true)
                const response = await postData(update_category, { _id: _id, categoryName: categoryName })
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    setImgBtnStatus(false)
                    setUploadBtn(false)
                    fetch_all_categories()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
    }

    const handleUpdateImage = async () => {
        setIsLoading(true)
        var formData = new FormData()
        formData.append("_id", _id)
        formData.append("img", img.bytes)
        const response = await postData(update_category_img, formData, true)
        setIsLoading(false)
        if (response.status) {
            toast.success(response.msg, alertOptions);
            setUploadBtn(false)
            setImgBtnStatus(false)
            fetch_all_categories()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const handleDelete = async (_id) => {
        console.log(_id)
        setIsLoading(true)
        const response = await postData(delete_category, { _id: _id })
        setIsLoading(false)
        console.log(response)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_categories()
        }
        else {
            toast.error(response.msg, alertOptions);

        }
    }

    const columns = [
        { field: 'sno', headerName: 'S.No', width: 90 },
        {
            field: 'categoryName',
            headerName: 'Category Name',
            width: 150,
            editable: false,
        },
        {
            field: 'imgUrl',
            headerName: 'Picture',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <ImageRender img={params.value} altTitle="category-img" />
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (<ActionIcons rowData={params.row} editAction={handleModelOpen} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Category" subtitle="All Categories are listed here" />
                <Button variant='contained' component="label" onClick={() => handleModelOpen(null, 'add')} >
                    Add New
                </Button>
            </div>
            {<TableRender data={categoryData} columns={columns} />}
            {editModal()}
        </Box>
    )

    function editModal() {

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
        };

        const handleIcon = (event) => {
            updateState({ img: { url: URL.createObjectURL(event.target.files[0]), bytes: event.target.files[0] } })
            if (modelMode == 'edit') {
                setImgBtnStatus(true)
                setUploadBtn(true)
            }
        }

        const handleImgCancel = () => {
            updateState({ img: { url: oldImg, bytes: '' } })
            updateState({ originalImg: oldImg })
            setImgBtnStatus(false)
            setUploadBtn(false)
        }


        return (
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Header title={modelMode == 'edit' ? "Edit" : "Add"} subtitle={modelMode == 'edit' ? "Edit your Category" : "Add new Category"} />

                        <IconButton onClick={closeModel}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label='CategoryName' value={categoryName} onChange={(e) => updateState({ "categoryName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center', }}>
                                <Button disabled={uploadBtn} variant='contained' component="label" >
                                    {modelMode == 'edit' ? 'Change' : "Select Picture"}
                                    <input onChange={handleIcon} hidden accept="image/*" multiple type="file" />
                                </Button>
                                <img src={img.url} height={40} />
                                {saveAndCancelButton()}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleReset} variant='outlined' fullWidth color="secondary">
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={handleSubmit} fullWidth color="secondary" >
                                {modelMode}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        )
        function saveAndCancelButton() {
            return (
                <div>
                    {imgBtnStatus ? <>
                        <IconButton
                            onClick={handleUpdateImage}
                            style={{ color: "Green" }}><Check /></IconButton><br />
                        <IconButton
                            onClick={handleImgCancel}
                            style={{ color: "Red" }}><Close /></IconButton></> : <></>}
                </div>
            )
        }
    }
}

export default Category


