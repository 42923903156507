import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { Box, Button } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { get_customers, ban_unBan_customer, delete_customer } from '../../config/constants';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { alertOptions } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const Customer = () => {
    const [customersData, setCustomersData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch_all_customers()
    }, [])

    const fetch_all_customers = async () => {
        setIsLoading(true);
        const response = await getData(get_customers);
        setIsLoading(false);
        console.log(response)
        if (response.status) {
            setCustomersData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const handleDelete = async (_id) => {
        setIsLoading(true)
        const response = await postData(delete_customer, { _id: _id })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_customers()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const banRetailer = async (rowData) => {
        setIsLoading(true)
        console.log(!rowData.status)
        const response = await postData(ban_unBan_customer, { _id: rowData._id, status: !rowData.status })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_customers()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const columns = [
        { field: 'sno', headerName: 'S.No', width: 50 },
        {
            field: 'fullName',
            headerName: 'Full Name',
            width: 150,
            editable: false,
        },
        {
            field: 'mob',
            headerName: 'Mobile',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 100,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'State',
            width: 100,
            editable: false,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 100,
            editable: false,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 150,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <Button onClick={() => banRetailer(params.row)} variant="contained" style={{ background: params.value ? "green" : 'Red' }}>
                    {params.value ? <VerifiedUserIcon /> : <NotInterestedIcon />}
                </Button>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 70,
            renderCell: (params) => (<ActionIcons rowData={params.row} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Customers" subtitle="All Customers are listed here" />
            </div>
            {<TableRender data={customersData} columns={columns} />}
        </Box>
    )

}

export default Customer;


