import { Routes, Route } from "react-router-dom"
import Login from './pages/login/Login'
import DashboardLayout from './pages/dashboardLayout/DashboardLayout'
import PrivateComponent from "./components/PrivateComponent";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist";
import 'react-toastify/dist/ReactToastify.css';
const App = () => {

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateComponent />}>
        <Route path='/dashboard/*' element={<DashboardLayout />} />
      </Route>
    </Routes>
  );
};

export default App;
