import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom/dist';
import { admin_login } from '../../config/constants';
import { postData } from '../../config/fetchServices';
import Loader from '../../components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { alertOptions } from '../../config/attributesData';


const Login = () => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()



    useEffect(() => {
        const user = localStorage.getItem('user')
        if (user) {
            navigate('/dashboard')
        }
    }, [])

    const validation = () => {
        var valid = true;
        if (!userName) {
            valid = false
        }
        if (!password) {
            valid = false
        }
        return valid
    }

    const handleLogin = async () => {
        if (validation()) {
            setIsLoading(true)
            const body = { userName: userName, password: password }
            const response = await postData(admin_login, body)
            setIsLoading(false)
            if (response.data.length == 1) {
                var result = response.data[0]
                localStorage.setItem('user', JSON.stringify(result))
                toast.success("Login Successfull", alertOptions);
                navigate('/dashboard')
            }
            else {
                toast.error("Please check your credentials", alertOptions);
            }
        }
        else {
            toast.warn("Please input the credentials", alertOptions);
        }
    }
    return (
        <Grid container>
            <ToastContainer />
            <Loader loading={isLoading}  />
            <Grid item xs={6}>
                <div className='login-left' />
            </Grid>
            <Grid item xs={6}>
                <div className='login-right'>
                    <div className='login-box'>
                        <img src={require('../../assets/jsv_cards_logo.png')} className='login-logo' />
                        <h3>Login to the JSV Admin</h3>

                        <input type='text' value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            className='textInput'
                            placeholder='Enter Username'
                        />

                        <input type='password' value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='textInput'
                            placeholder='Enter Password'
                        />

                        <div className='btn'
                            onClick={handleLogin}
                        >
                            Login
                        </div>
                    </div>

                </div>
            </Grid>
        </Grid>
    )
}

export default Login