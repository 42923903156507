import React from 'react'

const ImageRender = ({ img, altTitle}) => {
    return (
        <div style={{ alignItems: 'center', display: 'flex', height: "100%" }}>
            <img src={img} alt={altTitle} style={{ width: '50px', }} />
        </div>
    )
}

export default ImageRender