import React from 'react'
import './loader.css'
import { DialogContent, Modal } from '@mui/material'
const Loader = ({ loading }) => {
    return (
        <Modal open={loading}>
            <div className="loading-container">
                <div class="loader"></div>
            </div>
        </Modal>
    )
}

export default Loader


