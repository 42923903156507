
// export const api_url = 'http://localhost:4000/'
export const api_url = 'https://jsv-backend.vercel.app/'

export const admin_login = 'admin/admin_login'

// Category
export const get_categories = 'category/get_categories'
export const add_category = 'category/add_category'
export const update_category = 'category/update_category'
export const update_category_img = 'category/update_category_img'
export const delete_category = 'category/delete_category'

// SubCategory
export const get_subCategories = 'subCategory/get_subCategories'
export const add_subCategory = 'subCategory/add_subCategory'
export const update_subCategory = 'subCategory/update_subCategory'
export const update_subCategory_img = 'subCategory/update_subCategory_img'
export const delete_subCategory = 'subCategory/delete_subCategory'
export const get_subCategories_by_category = 'subCategory/get_subCategories_by_category'

// Products
export const get_products = 'product/get_products'
export const add_product = 'product/add_product';
export const update_product = 'product/update_product';
export const update_product_img = 'product/update_product_img';
export const delete_product = 'product/delete_product';
export const get_products_by_subcategory = 'product/get_products_by_subCategory'

// Product Pictures
export const get_product_pictures = 'productPicture/get_productPictures'
export const add_product_pictures = 'productPicture/add_productPictures'
export const update_product_pictures = 'productPicture/update_productPictures'
export const delete_product_pictures = 'productPicture/delete_productPictures'

// Retailer Banners
export const get_retailer_banners = 'retailerBanner/get_banners'
export const add_retailer_banner = 'retailerBanner/add_banner'
export const update_retailer_banner_img = 'retailerBanner/update_banner_img'
export const delete_retailer_banner = 'retailerBanner/delete_banner'

// Reatilers
export const get_retailers = 'retailer/get_retailers'
export const add_retailer = 'retailer/add_retailer'
export const update_reatiler = 'retailer/update_retailer'
export const update_retailer_profilePic = 'retailer/update_profilePic_img'
export const update_retailer_shopPic = 'retailer/update_shopPic_img'
export const delete_retailer = 'retailer/delete_retailer'
export const ban_unBan_retailer = 'retailer/ban_unBan_retailer'


// Admins
export const get_admins = 'admin/get_admins'
export const add_admin = 'admin/add_admin'
export const update_admin = 'admin/update_admin'
export const delete_admin = 'admin/delete_admin'
export const ban_unban_admin = 'admin/ban_unban_admin'



// Customers
export const get_customers = 'customer/get_customers'
export const ban_unBan_customer = 'customer/ban_unBan_customer'
export const delete_customer = 'customer/delete_customer'