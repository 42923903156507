import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { Box, Button, Grid, IconButton, Modal, TextField, Icon, FormLabel, RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { get_retailers, update_reatiler, add_retailer, update_retailer_profilePic, update_retailer_shopPic, delete_retailer, ban_unBan_retailer } from '../../config/constants';
import ImageRender from '../../components/imageRenderinTable/ImageRender';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { Check, Close } from '@mui/icons-material';
import { alertOptions, modelStyles } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const Retailer = () => {
    const [retailersData, setRetailersData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modelMode, setModalMode] = useState('edit')
    const [profileBtnStatus, setProfileBtnStatus] = useState(false)
    const [shopPicBtnStatus, setShopPicBtnStatus] = useState(false)

    const [imgBtnMode, setImgBtnMode] = useState(false)
    const [profileUploadBtn, setProfileUploadBtn] = useState(false)
    const [shopPicUploadBtn, setShopPicUploadBtn] = useState(false)

    const [state, setState] = useState({
        _id: '',
        userName: '',
        password: '',
        fullName: '',
        mob: '',
        mob2: '',
        email: '',
        address: '',
        city: '',
        stateName: '',
        idNumber: '',
        idType: '',
        profilePic: { url: require('../../assets/jsv_cards_logo.png'), bytes: '' },
        oldProfile: '',
        originalProfile: '',
        shopPic: { url: require('../../assets/jsv_cards_logo.png'), bytes: '' },
        oldShopPic: '',
        originalshopPic: ''
    })
    const { _id, userName, password, fullName, mob, mob2, email, idType, idNumber, shopName, address, city, stateName, profilePic, oldProfile, originalProfile, shopPic, oldShopPic, originalshopPic } = state

    useEffect(() => {
        fetch_all_retailers()
    }, [])

    const updateState = (data) => {
        setState((prevData) => {
            const newData = { ...prevData, ...data };
            return newData;
        })
    }

    const fetch_all_retailers = async () => {
        setIsLoading(true);
        const response = await getData(get_retailers);
        setIsLoading(false);
        if (response.status) {
            setRetailersData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const handleModelOpen = async (rowData, mode) => {
        setModalOpen(true)
        setModalMode(mode)
        setProfileBtnStatus(false)
        setShopPicBtnStatus(false)
        if (mode == 'edit') {
            try {
                updateState({
                    _id: rowData._id,
                    fullName: rowData.fullName,
                    userName: rowData.userName,
                    password: rowData.password,
                    shopName: rowData.shopName,
                    mob: rowData.mob,
                    mob2: rowData.mob2,
                    email: rowData.email,
                    city: rowData.city,
                    stateName: rowData.state,
                    address: rowData.address,
                    idType: rowData.idType,
                    idNumber: rowData.idNumber,
                    profilePic: { url: rowData.profilePicUrl, bytes: '' },
                    oldProfile: rowData.profilePicUrl,
                    originalProfile: { url: rowData.profilePicUrl, bytes: '' },
                    shopPic: { url: rowData.shopPicUrl, bytes: '' },
                    oldShopPic: rowData.shopPicUrl,
                    originalshopPic: { url: rowData.shopPicUrl, bytes: '' },
                });
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        }
        else {
            handleReset()
        }
    }

    const closeModel = () => {
        setModalOpen(false)
        setModalMode('edit')
        setProfileUploadBtn(false)
        setShopPicUploadBtn(false)
        setProfileBtnStatus(false)
        setShopPicBtnStatus(false)
    }

    const handleReset = () => {
        updateState(
            {
                fullName: '',
                userName: '',
                password: '',
                mob: '',
                mob2: '',
                email: '',
                address: '',
                city: '',
                stateName: '',
                idProof: '',
                shopName: '',
                profilePic: { "url": require('../../assets/jsv_cards_logo.png'), bytes: '' },
                shopPic: { "url": require('../../assets/jsv_cards_logo.png'), bytes: '' }
            })
    }

    const validation = () => {
        var isValid = true;
        if (!fullName) {
            toast.error('Full Name can not be blank')
            isValid = false
        }
        if (!userName) {
            toast.error('UserName can not be blank')
            isValid = false
        }
        if (!password) {
            toast.error('Password can not be blank')
            isValid = false
        }
        if (!email) {
            toast.error('Email can not be blank')
            isValid = false
        }
        if (!mob) {
            toast.error('Mobile Number can not be blank')
            isValid = false
        }
        if (!city) {
            toast.error('Please select a city')
            isValid = false
        }
        if (!stateName) {
            toast.error('Please select a State')
            isValid = false
        }
        if (!address) {
            toast.error('Please fill address')
            isValid = false
        }
        if (!shopName) {
            toast.error('Please Enter Shop Name')
            isValid = false
        }
        if (!profilePic.bytes) {
            toast.error('Please Select an Profile Picture')
            isValid = false
        }
        if (!shopPic.bytes) {
            toast.error('Please Select an Profile Picture')
            isValid = false
        }
        return isValid;
    }

    const editValidation = () => {
        var isValid = true;
        if (!fullName) {
            toast.error('Full Name can not be blank')
            isValid = false
        }
        if (!userName) {
            toast.error('UserName can not be blank')
            isValid = false
        }
        if (!password) {
            toast.error('Password can not be blank')
            isValid = false
        }
        if (!email) {
            toast.error('Email can not be blank')
            isValid = false
        }
        if (!mob) {
            toast.error('Mobile Number can not be blank')
            isValid = false
        }
        if (!city) {
            toast.error('Please select a city')
            isValid = false
        }
        if (!stateName) {
            toast.error('Please select a State')
            isValid = false
        }
        if (!address) {
            toast.error('Please fill address')
            isValid = false
        }
        if (!shopName) {
            toast.error('Please Enter Shop Name')
            isValid = false
        }
        return isValid;
    }

    const handleSubmit = async () => {
        if (modelMode == 'add') {
            if (validation()) {
                setIsLoading(true)
                var formData = new FormData()
                formData.append('userName', userName)
                formData.append('password', password)
                formData.append('fullName', fullName)
                formData.append('email', email)
                formData.append('mob', mob)
                formData.append('mob2', mob2)
                formData.append('idType', idType);
                formData.append('idNumber', idNumber);
                formData.append('shopName', shopName)
                formData.append('address', address)
                formData.append('city', city)
                formData.append('state', stateName)
                formData.append('profilePic', profilePic.bytes)
                formData.append('shopPic', shopPic.bytes)
                const response = await postData(add_retailer, formData, true)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_retailers()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
        else {
            if (editValidation()) {
                setIsLoading(true)
                var body = {
                    _id: _id,
                    userName: userName,
                    password: password,
                    fullName: fullName,
                    email: email,
                    mob: mob,
                    mob2: mob2,
                    idType: idType,
                    idNumber: idNumber,  
                    shopName: shopName,
                    address: address,
                    city: city,
                    state: stateName,
                }
                const response = await postData(update_reatiler, body)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    setProfileBtnStatus(false)
                    setShopPicBtnStatus(false)
                    setProfileUploadBtn(false)
                    setShopPicUploadBtn(false)
                    fetch_all_retailers()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
    }

    const handleUpdateImage = async (type) => {
        setIsLoading(true)
        var formData = new FormData()
        formData.append("_id", _id)
        if (type == 'profile') {
            formData.append("profilePic", profilePic.bytes)
        }
        else {
            formData.append("shopPic", shopPic.bytes)
        }
        const response = await postData(type == 'profile' ? update_retailer_profilePic : update_retailer_shopPic, formData, true)
        setIsLoading(false)
        if (response.status) {
            toast.success(response.msg, alertOptions);
            if (type == 'profile') {
                setProfileUploadBtn(false)
                setProfileBtnStatus(false)
            }
            else {
                setShopPicBtnStatus(false)
                setShopPicUploadBtn(false)
            }
            fetch_all_retailers()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const handleDelete = async (_id) => {
        setIsLoading(true)
        const response = await postData(delete_retailer, { _id: _id })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_retailers()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const banRetailer = async (rowData) => {
        setIsLoading(true)
        console.log(!rowData.status)
        const response = await postData(ban_unBan_retailer, { _id: rowData._id, status: !rowData.status })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_retailers()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const columns = [
        { field: 'sno', headerName: 'S.No', width: 50 },
        {
            field: 'shopName',
            headerName: 'Shop Name',
            width: 100,
            editable: false,
        },
        {
            field: 'fullName',
            headerName: 'Full Name',
            width: 100,
            editable: false,
        },
        {
            field: 'mob',
            headerName: 'Mobile',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            editable: false,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 200,
            editable: false,
        },
        {
            field: 'profilePicUrl',
            headerName: 'Profile',
            width: 70,
            editable: false,
            renderCell: (params) => (
                <ImageRender img={params.value} altTitle="profile-img" />
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <Button onClick={() => banRetailer(params.row)} variant="contained" style={{ background: params.value ? "green" : 'Red' }}>
                    {params.value ? <VerifiedUserIcon /> : <NotInterestedIcon />}
                </Button>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 70,
            renderCell: (params) => (<ActionIcons rowData={params.row} editAction={handleModelOpen} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Retailers" subtitle="All Retailers are listed here" />
                <Button variant='contained' component="label" onClick={() => handleModelOpen(null, 'add')} >
                    Add New
                </Button>
            </div>
            {<TableRender data={retailersData} columns={columns} />}
            {editModal()}
        </Box>
    )

    function editModal() {

        const handleIcon = (event, type) => {
            if (type == 'profile') {
                updateState({ profilePic: { url: URL.createObjectURL(event.target.files[0]), bytes: event.target.files[0] } })
                setImgBtnMode('profile')
                setProfileBtnStatus(true)
            }
            else {
                updateState({ shopPic: { url: URL.createObjectURL(event.target.files[0]), bytes: event.target.files[0] } })
                setImgBtnMode('shop')
                setShopPicBtnStatus(true)
            }
            if (modelMode == 'edit') {
                if (type == 'profile') {
                    setProfileUploadBtn(true)
                    profileBtnStatus(true)
                }
                else {
                    setShopPicUploadBtn(true)
                    shopPicBtnStatus(true)
                }
            }
        }

        const handleImgCancel = (type) => {
            alert(type)
            if (type == 'profile') {
                updateState({ profilePic: { url: oldProfile, bytes: '' } })
                updateState({ originalProfile: oldProfile })
                setProfileUploadBtn(false)
                setProfileBtnStatus(false)
            }
            else {
                console.log(oldShopPic)
                updateState({ shopPic: { url: oldShopPic, bytes: '' } })
                updateState({ originalshopPic: oldShopPic })
                setShopPicUploadBtn(false)
                setShopPicBtnStatus(false)
            }
        }

        return (
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelStyles}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Header title={modelMode == 'edit' ? "Edit" : "Add"} subtitle={modelMode == 'edit' ? "Edit Retailer" : "Add New Retailer"} />
                        <IconButton onClick={closeModel}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Full Name' value={fullName} onChange={(e) => updateState({ "fullName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Shop Name' value={shopName} onChange={(e) => updateState({ "shopName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='UserName' value={userName} onChange={(e) => updateState({ "userName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Password' value={password} onChange={(e) => updateState({ "password": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Mobile' value={mob} onChange={(e) => updateState({ "mob": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Email' value={email} onChange={(e) => updateState({ "email": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='City' value={city} onChange={(e) => updateState({ "city": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='State' value={stateName} onChange={(e) => updateState({ "stateName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Address' value={address} onChange={(e) => updateState({ "address": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Alternate Mobile' value={mob2} onChange={(e) => updateState({ "mob2": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                <RadioGroup
                                    row
                                    value={idType}
                                    defaultValue={"AADHAR"}
                                    onChange={(e) => updateState({ "idType": e.target.value })}
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="AADHAR" control={<Radio />} label="AADHAR" />
                                    <FormControlLabel value="PAN" control={<Radio />} label="PAN" />
                                    <FormControlLabel value="GSTIN" control={<Radio />} label="GTSIN" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='ID Number' value={idNumber} onChange={(e) => updateState({ "idNumber": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center', }}>
                                <Button disabled={profileUploadBtn} variant='contained' component="label" >
                                    {modelMode == 'edit' ? 'Change Profile Pic' : "Profile Picture"}
                                    <input onChange={(event) => handleIcon(event, 'profile')} hidden accept="image/*" multiple type="file" />
                                </Button>
                                <img src={profilePic.url} height={40} />
                                {modelMode == 'edit' && profileBtnStatus && saveAndCancelButton('profile')}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center', }}>
                                <Button disabled={shopPicUploadBtn} variant='contained' component="label" >
                                    {modelMode == 'edit' ? 'Change Shop Pic' : "Shop Picture"}
                                    <input onChange={(event) => handleIcon(event, 'shop')} hidden accept="image/*" multiple type="file" />
                                </Button>
                                <img src={shopPic.url} height={40} />
                                {modelMode == 'edit' && shopPicBtnStatus && saveAndCancelButton('shop')}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            {modelMode == 'edit' ?
                                < Button onClick={closeModel} variant='outlined' fullWidth color="secondary">
                                    Cancel
                                </Button>
                                :
                                < Button onClick={handleReset} variant='outlined' fullWidth color="secondary">
                                    Reset
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={handleSubmit} fullWidth color="secondary" >
                                {modelMode}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        )

        function saveAndCancelButton(type) {
            return (
                <div>
                    {imgBtnMode ? <>
                        <IconButton
                            onClick={() => handleUpdateImage(type)}
                            style={{ color: "Green" }}><Check /></IconButton><br />
                        <IconButton
                            onClick={() => handleImgCancel(type)}
                            style={{ color: "Red" }}><Close /></IconButton></> : <></>}
                </div>
            )
        }
    }
}

export default Retailer


