import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const ActionIcons = ({ editAction, deleteAction, rowData }) => {
    return (
        <div>
            {editAction &&
                <IconButton onClick={() => editAction(rowData, 'edit')} aria-label="Edit" color="success">
                    <EditIcon />
                </IconButton>
            }
            {deleteAction &&
                <IconButton aria-label="Delete" color="error" onClick={() => deleteAction(rowData._id)}>
                    <DeleteIcon />
                </IconButton>}
        </div>
    )
}
