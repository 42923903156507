import React, { useState } from 'react'
import Header from '../../components/Header'
import { Box, Button, Grid, IconButton, Modal, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { add_product, add_subCategory, delete_product, delete_subCategory, get_categories, get_products, get_subCategories, get_subCategories_by_category, update_product, update_product_img, update_subCategory, update_subCategory_img } from '../../config/constants';
import { useEffect } from 'react';
import ImageRender from '../../components/imageRenderinTable/ImageRender';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { Check, Close, LogoDevRounded } from '@mui/icons-material';
import { alertOptions, modelStyles } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';

const Product = () => {
    const [productsData, setProductsData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modelMode, setModalMode] = useState('edit')
    const [imgBtnStatus, setImgBtnStatus] = useState(false)
    const [uploadBtn, setUploadBtn] = useState(false)

    const [state, setState] = useState({
        _id: '',
        categoryId: '',
        subCategoryId: '',
        productName: '',
        price: '',
        offerPrice: '',
        wholeSalePrice: '',
        wholeSaleOfferPrice: '',
        stock: '',
        unit: '',
        shortDesc: '',
        longDesc: '',
        color: '',
        height: '',
        width: '',
        weight: '',
        productCode: '',
        img: { url: require('../../assets/jsv_cards_logo.png'), bytes: '' },
        oldImg: '',
        originalImg: ''
    })

    const { _id, categoryId, subCategoryId, productName, price,
        wholeSalePrice, wholeSaleOfferPrice,
        offerPrice, unit, stock, shortDesc, longDesc,
        productCode, color, height, weight, width, img, oldImg, originalImg } = state

    useEffect(() => {
        fetch_all_products()
        fetch_all_categories()
    }, [])

    const updateState = (data) => {
        setState((prevData) => {
            const newData = { ...prevData, ...data };
            return newData;
        })
    }

    const fetch_all_categories = async () => {
        setIsLoading(true);
        const response = await getData(get_categories);
        setIsLoading(false);
        if (response.status) {
            setCategoryData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const fetch_subCategories_by_category = async (id) => {
        setIsLoading(true);
        const response = await postData(get_subCategories_by_category, { "categoryId": id });
        setIsLoading(false);
        if (response.status) {
            setSubCategoryData(response.data.reverse());
        }
    };

    const fetch_all_products = async () => {
        setIsLoading(true)
        const response = await getData(get_products);
        setIsLoading(false);
        if (response.status) {
            setProductsData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }

    }

    const handleModelOpen = async (rowData, mode) => {
        setModalOpen(true)
        setModalMode(mode)
        setUploadBtn(false)
        if (mode == 'edit') {
            updateState({
                _id: rowData._id,
                categoryId: rowData.categoryId._id,
                subCategoryId: rowData.subCategoryId._id,
                productName: rowData.productName,
                productCode: rowData.productCode,
                price: rowData.price,
                offerPrice: rowData.offerPrice,
                wholeSalePrice: rowData.wholeSalePrice,
                wholeSaleOfferPrice: rowData.wholeSaleOfferPrice,
                stock: rowData.stock,
                unit: rowData.unit,
                color: rowData.color,
                height: rowData.height,
                width: rowData.width,
                weight: rowData.weight,
                shortDesc: rowData.shortDesc,
                longDesc: rowData.longDesc,
                img: { url: rowData.imgUrl, bytes: '' },
                originalImg: { url: rowData.imgUrl, bytes: '' },
                oldImg: rowData.imgUrl,
            });
            fetch_subCategories_by_category(rowData.categoryId._id)
        }
        else {
            handleReset()
        }
    }

    const closeModel = () => {
        setModalOpen(false)
        setModalMode('edit')
        setImgBtnStatus(false)
    }

    const handleReset = () => {
        updateState(
            {
                _id: '',
                categoryId: '',
                subCategoryId: '',
                productName: '',
                price: '',
                offerPrice: '',
                wholeSalePrice: '',
                wholeSaleOfferPrice: '',
                stock: '',
                unit: '',
                shortDesc: '',
                longDesc: '',
                color: '',
                height: '',
                width: '',
                weight: '',
                productCode: '',
                img: { url: require('../../assets/jsv_cards_logo.png'), bytes: '' },
                oldImg: '',
                originalImg: ''
            })
    }

    const validation = () => {
        var isValid = true;
        if (!categoryId) {
            toast.error('Please Select a Category')
            isValid = false
        }
        if (!subCategoryId) {
            toast.error('Please Select a Sub Category')
            isValid = false
        }
        if (!productName) {
            toast.error('Product Name can not be blank')
            isValid = false
        }
        if (!price) {
            toast.error('Price can not be blank')
            isValid = false
        }
        if (!offerPrice) {
            toast.error('Offer Price  can not be blank')
            isValid = false
        }
        if (!stock) {
            toast.error('Stock can not be blank')
            isValid = false
        }
        if (!unit) {
            toast.error('Unit can not be blank')
            isValid = false
        }
        if (!shortDesc) {
            toast.error('Short Description can not be blank')
            isValid = false
        }
        if (!longDesc) {
            toast.error('Long Description can not be blank')
            isValid = false
        }
        if (!width) {
            toast.error('Width can not be blank')
            isValid = false
        }
        if (!height) {
            toast.error('Height can not be blank')
            isValid = false
        }
        if (!weight) {
            toast.error('Weight can not be blank')
            isValid = false
        }
        if (!img.bytes) {
            toast.error('Please select an Sub Category image')
            isValid = false
        }
        return isValid;
    }

    const editValidation = () => {
        var isValid = true
        if (!categoryId) {
            toast.error('Please Select a Category')
            isValid = false
        }
        if (!subCategoryId) {
            toast.error('Please Select a Sub Category')
            isValid = false
        }
        if (!productName) {
            toast.error('Product Name can not be blank')
            isValid = false
        }
        if (!price) {
            toast.error('Price can not be blank')
            isValid = false
        }
        if (!offerPrice) {
            toast.error('Offer Price  can not be blank')
            isValid = false
        }
        if (!stock) {
            toast.error('Stock can not be blank')
            isValid = false
        }
        if (!unit) {
            toast.error('Unit can not be blank')
            isValid = false
        }
        if (!shortDesc) {
            toast.error('Short Description can not be blank')
            isValid = false
        }
        if (!longDesc) {
            toast.error('Long Description can not be blank')
            isValid = false
        }
        if (!width) {
            toast.error('Width can not be blank')
            isValid = false
        }
        if (!height) {
            toast.error('Height can not be blank')
            isValid = false
        }
        if (!weight) {
            toast.error('Weight can not be blank')
            isValid = false
        }
        return isValid
    }

    const handleSubmit = async () => {
        if (modelMode == 'add') {
            if (validation()) {
                setIsLoading(true)
                var formData = new FormData()
                formData.append('categoryId', categoryId)
                formData.append('subCategoryId', subCategoryId)
                formData.append('productName', productName)
                formData.append('price', price)
                formData.append('offerPrice', offerPrice)
                formData.append('wholeSalePrice', wholeSalePrice)
                formData.append('wholeSaleOfferPrice', wholeSaleOfferPrice)
                formData.append('stock', stock)
                formData.append('unit', unit)
                formData.append('color', color)
                formData.append('shortDesc', shortDesc)
                formData.append('longDesc', longDesc)
                formData.append('height', height)
                formData.append('width', width)
                formData.append('weight', weight)
                formData.append('productCode', productCode)
                formData.append('img', img.bytes)
                const response = await postData(add_product, formData, true)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_products()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
        else {
            if (editValidation()) {
                setIsLoading(true)
                var body = {
                    _id: _id,
                    categoryId: categoryId,
                    subCategoryId: subCategoryId,
                    productName: productName,
                    price: price,
                    offerPrice: offerPrice,
                    wholeSalePrice: wholeSalePrice,
                    wholeSaleOfferPrice: wholeSaleOfferPrice,
                    productCode: productCode,
                    stock: stock,
                    unit: unit,
                    color: color,
                    shortDesc: shortDesc,
                    longDesc: longDesc,
                    height: height,
                    width: width,
                    weight: weight
                }
                const response = await postData(update_product, body)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    setImgBtnStatus(false)
                    setUploadBtn(false)
                    fetch_all_products()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
    }

    const handleUpdateImage = async () => {
        setIsLoading(true)
        var formData = new FormData()
        formData.append("_id", _id)
        formData.append("img", img.bytes)
        const response = await postData(update_product_img, formData, true)
        setIsLoading(false)
        if (response.status) {
            toast.success(response.msg, alertOptions);
            setUploadBtn(false)
            setImgBtnStatus(false)
            fetch_all_products()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const handleDelete = async (_id) => {
        setIsLoading(true)
        const response = await postData(delete_product, { _id: _id })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_products()
        }
        else {
            toast.error(response.msg, alertOptions);

        }
    }

    const columns = [
        {
            field: 'sno',
            headerName: 'S.No',
            width: 20
        },
        {
            field: 'categoryId',
            headerName: 'Category',
            width: 100,
            editable: false,
            renderCell: (params) => params.value?.categoryName
        },
        {
            field: 'subCategoryId',
            headerName: 'Sub Category',
            width: 100,
            editable: false,
            renderCell: (params) => params.value?.subCategoryName
        },
        {
            field: 'productName',
            headerName: 'Product Name',
            width: 150,
            editable: false,
        },
        {
            field: 'productCode',
            headerName: 'Code',
            width: 100,
            editable: false,
        },
        {
            field: 'imgUrl',
            headerName: 'Picture',
            width: 80,
            editable: false,
            renderCell: (params) => (
                <ImageRender img={params.value} altTitle="sub-category-img" />
            ),
        },
        {
            field: 'offerPrice',
            headerName: 'Customer Price',
            width: 80,
            editable: false,
            renderCell: (params) => {
                return (<div style={{ color: 'blue' }}>
                    {params.value}/-
                </div>)
            }
        },
        {
            field: 'wholeSaleOfferPrice',
            headerName: 'Wholesale Price',
            width: 80,
            editable: false,
            renderCell: (params) => {
                return (<div style={{ color: 'green' }}>
                    {params.value}/-
                </div>)
            }
        },
        {
            field: 'stock',
            headerName: 'Stock',
            width: 80,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            renderCell: (params) => (<ActionIcons rowData={params.row} editAction={handleModelOpen} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Products" subtitle="All Products are listed here" />
                <Button variant='contained' component="label" onClick={() => handleModelOpen(null, 'add')} >
                    Add New
                </Button>
            </div>
            {<TableRender data={productsData} columns={columns} />}
            {editModal()}
        </Box>
    )

    function editModal() {

        const handleCategory = (value) => {
            updateState({ categoryId: value })
            fetch_subCategories_by_category(value)
        }

        const handleIcon = (event) => {
            updateState({ img: { url: URL.createObjectURL(event.target.files[0]), bytes: event.target.files[0] } })
            if (modelMode == 'edit') {
                setImgBtnStatus(true)
                setUploadBtn(true)
            }
        }

        const handleImgCancel = () => {
            updateState({ img: { url: oldImg, bytes: '' } })
            updateState({ originalImg: oldImg })
            setImgBtnStatus(false)
            setUploadBtn(false)
        }

        return (
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelStyles}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Header title={modelMode == 'edit' ? "Edit" : "Add"} subtitle={modelMode == 'edit' ? "Edit your product" : "Add new product"} />
                        <IconButton onClick={closeModel}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoryId}
                                    label="Category"
                                    onChange={(e) => handleCategory(e.target.value)}
                                >
                                    <MenuItem value={null} disabled>Select Category</MenuItem>
                                    {renderCategoryList()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subCategoryId}
                                    label="Sub Category"
                                    onChange={(e) => updateState({ "subCategoryId": e.target.value })}
                                >
                                    <MenuItem value={null} disabled>Select Sub Category</MenuItem>
                                    {renderSubCategoryList()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField label='Product Name' value={productName} onChange={(e) => updateState({ "productName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Price' value={price} onChange={(e) => updateState({ "price": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Offer Price' value={offerPrice} onChange={(e) => updateState({ "offerPrice": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Wholesale Price' value={wholeSalePrice} onChange={(e) => updateState({ "wholeSalePrice": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Wholesale Offer Price' value={wholeSaleOfferPrice} onChange={(e) => updateState({ "wholeSaleOfferPrice": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Product Code' value={productCode} onChange={(e) => updateState({ "productCode": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Unit' value={unit} onChange={(e) => updateState({ "unit": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Stock' value={stock} onChange={(e) => updateState({ "stock": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Color' value={color} onChange={(e) => updateState({ "color": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Height' value={height} onChange={(e) => updateState({ "height": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='Width' value={width} onChange={(e) => updateState({ "width": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField label='weight' value={weight} onChange={(e) => updateState({ "weight": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6} md={12} lg={12}>
                            <TextField label='Short Description' value={shortDesc} onChange={(e) => updateState({ "shortDesc": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6} md={12} lg={12}>
                            <TextField label='Long Description' value={longDesc} onChange={(e) => updateState({ "longDesc": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6} md={12} lg={12}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center', }}>
                                <Button disabled={uploadBtn} variant='contained' component="label" >
                                    {modelMode == 'edit' ? 'Change' : "Select Picture"}
                                    <input onChange={handleIcon} hidden accept="image/*" multiple type="file" />
                                </Button>
                                <img src={img.url} height={40} />
                                {saveAndCancelButton()}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleReset} variant='outlined' fullWidth color="secondary">
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={handleSubmit} fullWidth color="secondary" >
                                {modelMode}
                            </Button>
                        </Grid>
                    </Grid>
                </Box >
            </Modal >
        )

        function renderCategoryList() {
            return (
                categoryData.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.categoryName}</MenuItem>
                ))
            );
        }

        function renderSubCategoryList() {
            return (
                subCategoryData.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.subCategoryName}</MenuItem>
                ))
            );
        }

        function saveAndCancelButton() {
            return (
                <div>
                    {imgBtnStatus ? <>
                        <IconButton
                            onClick={handleUpdateImage}
                            style={{ color: "Green" }}><Check /></IconButton><br />
                        <IconButton
                            onClick={handleImgCancel}
                            style={{ color: "Red" }}><Close /></IconButton></> : <></>}
                </div>
            )
        }
    }
}

export default Product


