export const getSerialNumber = (array) => {
    return (
        array.map((item, index) => {
            return ({
                ...item,
                sno: index + 1
            }
            )
        })
    )
}