import { api_url } from "./constants";
import axios from 'axios';

const postData = async (url, body, isFile = false) => {
    try {
        const headers = {
            headers: { "content-type": isFile ? "multipart/form-data" : "application/json" }
        }
        const response = await axios.post(`${api_url + url}`, body, headers)
        var data = response.data
        return data
    }
    catch (e) {
        console.log(e)
        return false
    }
}


const getData = async (url) => {
    try {
        const response = await axios.get(`${api_url + url}`)
        const data = response.data
        return data
    }
    catch (e) {
        return false
    }
}


export { postData, getData }