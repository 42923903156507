import React, { useState } from 'react'
import Header from '../../components/Header'
import { Box, Button, Grid, IconButton, Modal, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { add_product_pictures, delete_product_pictures, get_categories, get_product_pictures, get_products_by_subcategory, get_subCategories, get_subCategories_by_category, update_product, update_product_img, update_product_pictures } from '../../config/constants';
import { useEffect } from 'react';
import ImageRender from '../../components/imageRenderinTable/ImageRender';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { Close } from '@mui/icons-material';
import { alertOptions, modelStyles } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';

const ProductPictures = () => {
    const [produtPicturesData, setProductPicturesData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])
    const [productsData, setProductsData] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modelMode, setModalMode] = useState('edit')

    const [state, setState] = useState({
        _id: '',
        categoryId: '',
        subCategoryId: '',
        productId: '',
        productImages: [],
    })

    const { _id, categoryId, subCategoryId, productId, productImages } = state

    useEffect(() => {
        fetch_all_productPictures()
        fetch_all_categories()
    }, [])

    const fetch_all_productPictures = async () => {
        setIsLoading(true)
        const response = await getData(get_product_pictures);
        setIsLoading(false);
        if (response.status) {
            setProductPicturesData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }

    }

    const fetch_all_categories = async () => {
        setIsLoading(true);
        const response = await getData(get_categories);
        setIsLoading(false);
        if (response.status) {
            setCategoryData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const fetch_subCategories_by_category = async (id) => {
        setIsLoading(true);
        const response = await postData(get_subCategories_by_category, { "categoryId": id });
        setIsLoading(false);
        if (response.status) {
            setSubCategoryData(response.data.reverse());
        }
    };

    const fetch_products_by_subcategory = async (id) => {
        setIsLoading(true)
        const response = await postData(get_products_by_subcategory, { "subCategoryId": id });
        setIsLoading(false);
        if (response.status) {
            setProductsData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    }

    const updateState = (data) => {
        setState((prevData) => {
            const newData = { ...prevData, ...data };
            return newData;
        })
    }

    const handleModelOpen = async (rowData, mode) => {
        setModalOpen(true)
        setModalMode(mode)
        if (mode == 'edit') {
            updateState({
                _id: rowData._id,
                categoryId: rowData.categoryId._id,
                subCategoryId: rowData.subCategoryId._id,
                productId: rowData.productId._id,
                productImages: rowData.imgUrls.map((url) => ({ url, bytes: null }))
            });
            fetch_subCategories_by_category(rowData.categoryId._id)
            fetch_products_by_subcategory(rowData.subCategoryId._id)
        }
        else {
            handleReset()
        }
    }

    const closeModel = () => {
        setModalOpen(false)
        setModalMode('edit')
    }

    const handleReset = () => {
        updateState(
            {
                _id: '',
                categoryId: '',
                subCategoryId: '',
                productId: '',
                productImages: [],

            })
    }

    const validation = () => {
        var isValid = true;
        if (!categoryId) {
            toast.error('Please Select a Category')
            isValid = false
        }
        if (!subCategoryId) {
            toast.error('Please Select a Sub Category')
            isValid = false
        }
        if (!productId) {
            toast.error('Please Select a Product')
            isValid = false
        }
        if (!productImages.length) {
            toast.error('Please select at least one image')
            isValid = false
        }
        return isValid;
    }

    const editValidation = () => {
        var isValid = true
        if (!categoryId) {
            toast.error('Please Select a Category')
            isValid = false
        }
        if (!subCategoryId) {
            toast.error('Please Select a Sub Category')
            isValid = false
        }
        if (!productId) {
            toast.error('Product Name can not be blank')
            isValid = false
        }
        return isValid
    }

    const handleSubmit = async () => {
        if (modelMode == 'add') {
            if (validation()) {
                setIsLoading(true)
                var formData = new FormData()
                formData.append('categoryId', categoryId)
                formData.append('subCategoryId', subCategoryId)
                formData.append('productId', productId)
                productImages.forEach((image, index) => {
                    formData.append(`productImages[${index}]`, image.bytes)
                });
                const response = await postData(add_product_pictures, formData, true)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_productPictures()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
        else {
            if (editValidation()) {
                setIsLoading(true)
                var body = {
                    _id: _id,
                    categoryId: categoryId,
                    subCategoryId: subCategoryId,
                    productId: productId,
                }
                const response = await postData(update_product_pictures, body)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_productPictures()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
    }

    const handleDelete = async (_id) => {
        setIsLoading(true)
        const response = await postData(delete_product_pictures, { _id: _id })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_productPictures()
        }
        else {
            toast.error(response.msg, alertOptions);

        }
    }

    const columns = [
        {
            field: 'sno',
            headerName: 'S.No',
            width: 20
        },
        {
            field: 'productId',
            headerName: 'Product Name',
            width: 150,
            editable: false,
            renderCell: (params) => params.value?.productName
        },
        {
            field: 'imgUrls',
            headerName: 'Pictures',
            width: '500',
            editable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {params?.value.map((item) => {
                        return (
                            <ImageRender img={item} altTitle="sub-category-img" />
                        )
                    })}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            renderCell: (params) => (<ActionIcons rowData={params.row} editAction={handleModelOpen} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Product Pictures" subtitle="All Products Pictures are listed here" />
                <Button variant='contained' component="label" onClick={() => handleModelOpen(null, 'add')} >
                    Add New
                </Button>
            </div>
            {<TableRender data={produtPicturesData} columns={columns} />}
            {editModal()}
        </Box>
    )

    function editModal() {

        const handleCategory = (value) => {
            updateState({ categoryId: value })
            fetch_subCategories_by_category(value)
        }

        const handleSubCategory = (value) => {
            updateState({ subCategoryId: value })
            fetch_products_by_subcategory(value)
        }


        const handleIcon = (event) => {
            const files = Array.from(event.target.files);
            const newImages = files.map(file => ({
                url: URL.createObjectURL(file),
                bytes: file,
            }));
            updateState({ productImages: [...productImages, ...newImages] })
        }

        return (
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelStyles}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Header title={modelMode == 'edit' ? "Edit" : "Add"} subtitle={modelMode == 'edit' ? "Edit Product Pictures" : "Add new product Pictures"} />
                        <IconButton onClick={closeModel}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoryId}
                                    label="Category"
                                    onChange={(e) => handleCategory(e.target.value)}
                                >
                                    <MenuItem value={null} disabled>Select Category</MenuItem>
                                    {renderCategoryList()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subCategoryId}
                                    label="Sub Category"
                                    onChange={(e) => handleSubCategory(e.target.value)}
                                >
                                    <MenuItem value={null} disabled>Select Sub Category</MenuItem>
                                    {renderSubCategoryList()}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={productId}
                                    label="Product"
                                    onChange={(e) => updateState({ "productId": e.target.value })}
                                >
                                    <MenuItem value={null} disabled>Select Product</MenuItem>
                                    {renderProductsList()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center', }}>
                                {modelMode == 'add' &&
                                    < Button variant='contained' component="label" >
                                        Select Pictures
                                        <input onChange={handleIcon} hidden accept="image/*" multiple type="file" />
                                    </Button>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{ display: 'flex', gap: 20, height: '100%', alignItems: 'center' }}>
                                {productImages &&
                                    productImages.map((image, index) => (
                                        <img key={index} src={image.url} height={40} alt={`Product ${index}`} />
                                    ))
                                }
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleReset} variant='outlined' fullWidth color="secondary">
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={handleSubmit} fullWidth color="secondary" >
                                {modelMode}
                            </Button>
                        </Grid>
                    </Grid>
                </Box >
            </Modal >
        )

        function renderCategoryList() {
            return (
                categoryData.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.categoryName}</MenuItem>
                ))
            );
        }

        function renderSubCategoryList() {
            return (
                subCategoryData.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.subCategoryName}</MenuItem>
                ))
            );
        }

        function renderProductsList() {
            return (
                productsData.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.productName}</MenuItem>
                ))
            );
        }
    }
}

export default ProductPictures


