import React, { useState } from 'react'
import Header from '../../components/Header'
import { Box, Button, Grid, IconButton, Modal, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from '../../config/fetchServices';
import { get_admins, add_admin, update_admin, delete_admin, ban_unban_admin } from '../../config/constants';
import { useEffect } from 'react';
import Loader from '../../components/loader/Loader';
import { ActionIcons } from '../../config/apiFunctions.js/ApiFunctions';
import { Close } from '@mui/icons-material';
import { alertOptions, modelStyles } from '../../config/attributesData';
import { ToastContainer, toast } from 'react-toastify';
import TableRender from '../../components/tableRender/TableRender';

const Admin = () => {
    const [adminData, setAdminData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modelMode, setModalMode] = useState('edit')
    const [state, setState] = useState({
        _id: '',
        userName: '',
        password: ''
    })
    const { _id, userName, password } = state

    useEffect(() => {
        fetch_all_admins()
    }, [])

    const updateState = (data) => {
        setState((prevData) => {
            const newData = { ...prevData, ...data };
            return newData;
        })
    }

    const fetch_all_admins = async () => {
        setIsLoading(true);
        const response = await getData(get_admins);
        setIsLoading(false);
        if (response.status) {
            setAdminData(response.data.reverse());
        }
        else {
            toast.error("Internal Server Error", alertOptions)
        }
    };

    const handleModelOpen = async (rowData, mode) => {
        setModalOpen(true)
        setModalMode(mode)
        if (mode == 'edit') {
            try {
                updateState({
                    _id: rowData._id,
                    userName: rowData.userName,
                    password: rowData.password
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        else {
            handleReset()
        }
    }

    const closeModel = () => {
        setModalOpen(false)
        setModalMode('edit')
    }

    const handleReset = () => {
        updateState(
            {
                _id: '',
                userName: '',
                password: ''
            })
    }

    const validation = () => {
        var isValid = true;
        if (!userName) {
            toast.error('UserName can not be blank')
            isValid = false
        }

        if (!password) {
            toast.error('Password can not be blank')
            isValid = false
        }
        return isValid;
    }

    const handleSubmit = async () => {
        if (modelMode == 'add') {
            if (validation()) {
                setIsLoading(true)
                var body = {
                    userName: userName,
                    password: password
                }
                const response = await postData(add_admin, body)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_admins()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
        else {
            if (validation()) {
                setIsLoading(true)
                var body = { _id: _id, userName: userName, password: password }
                const response = await postData(update_admin, body)
                setIsLoading(false)
                if (response.status) {
                    toast.success(response.msg, alertOptions);
                    handleReset()
                    setModalOpen(false)
                    fetch_all_admins()
                }
                else {
                    toast.error(response.msg, alertOptions);
                }
            }
        }
    }

    const handleDelete = async (_id) => {
        setIsLoading(true)
        const response = await postData(delete_admin, { _id: _id })
        setIsLoading(false)
        if (response.status == true) {
            toast.success(response.msg, alertOptions);
            fetch_all_admins()
        }
        else {
            toast.error(response.msg, alertOptions);
        }
    }

    const columns = [
        { field: 'sno', headerName: 'S.No', width: 90 },
        {
            field: 'userName',
            headerName: 'UserName',
            width: 200,
            editable: false
        },
        {
            field: 'password',
            headerName: 'Password',
            width: 150,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (<ActionIcons rowData={params.row} editAction={handleModelOpen} deleteAction={handleDelete} />),
        }
    ]

    return (
        <Box mx="20px">
            <ToastContainer />
            <Loader loading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header title="Admin" subtitle="All Admins are listed here" />
                <Button variant='contained' component="label" onClick={() => handleModelOpen(null, 'add')} >
                    Add New
                </Button>
            </div>
            {<TableRender data={adminData} columns={columns} />}
            {editModal()}
        </Box>
    )

    function editModal() {
        return (
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelStyles}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Header title={modelMode == 'edit' ? "Edit" : "Add"} subtitle={modelMode == 'edit' ? "Edit Admin Credentials" : "Add New Admin"} />
                        <IconButton onClick={closeModel}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label='UserName' value={userName} onChange={(e) => updateState({ "userName": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label='Password' value={password} onChange={(e) => updateState({ "password": e.target.value })} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleReset} variant='outlined' fullWidth color="secondary">
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={handleSubmit} fullWidth color="secondary" >
                                {modelMode}
                            </Button>
                        </Grid>
                    </Grid>
                </Box >
            </Modal >
        )
    }
}
export default Admin


