import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import { getSerialNumber } from '../../config/methods'

const TableRender = ({ data, columns }) => {
    const rows = getSerialNumber(data)
    return (
        <>
            {rows.length > 0 ? (
                <DataGrid
                    rows={rows}
                    rowSelection={false}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rowHeight={60}
                    pagination
                    // style={{ width: '70%' }}
                    scrollbarSize={30}
                    pageSizeOptions={[5]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                />
            ) : (
                <div style={{ height: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={require('../../assets/no_data.png')} height={200} />
                    <h3>No data found</h3>
                </div>
            )}
        </>
    )
}

export default TableRender