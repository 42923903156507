import { isOverflown } from "@mui/x-data-grid/utils/domUtils";

export const alertOptions = {
    autoClose: 1000,
    hideProgressBar: false,
    position: "top-right",
    pauseOnHover: false
};

export const modelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    maxHeight: '80%',
    overflowY: 'scroll',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}