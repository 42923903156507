import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "../global/sidebar/sidebarContext";
import Topbar from '../global/Topbar'
import Dashboard from "../dashboard/Dashboard";
import Category from '../category/Category'
import SubCategory from '../subCategory/SubCategory'
import Product from "../products/products";
import Retailer from "../retailer/Retailer";
import Admin from "../admins/Admin";
import Customer from "../customer/Customer";
import RetailerBanner from "../banners/RetailerBanner";
import ProductPictures from "../productPictures/ProductPictures";
const DashboardLayout = () => {
    const [theme, colorMode] = useMode();
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <MyProSidebarProvider>
                    <div style={{ height: "100%", width: "100%" }}>
                        <main>
                            <Topbar />
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path='/category' element={<Category />} />
                                <Route path='/subcategory' element={<SubCategory />} />
                                <Route path='/product' element={<Product />} />
                                <Route path='/productPictures' element={<ProductPictures />} />
                                <Route path='/retailerBanner' element={<RetailerBanner />} />
                                <Route path='/retailer' element={<Retailer />} />
                                <Route path='/customer' element={<Customer />} />
                                <Route path='/admin' element={<Admin />} />
                            </Routes>
                        </main>
                    </div>
                </MyProSidebarProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

}
export default DashboardLayout;
